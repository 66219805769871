@use '../../includes' as *;

.search_video_and_web_results_wrapper {
  margin-block-end: var(--spacing-block);
}

.search_video_and_web_results {
  @include video-detail-thumbnail-list;
}

.search_video_and_web_results__count_and_sort {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-block-end: $g20;

  @include breakpoint($sm) {
    margin-block-end: $g28;
  }
}

.search_video_and_web_results__result_count {
  @include h2;
}

.search_video_and_web_results__sort_by {
  display: none;

  @include breakpoint($sm) {
    display: flex;
  }

  inset-block-start: 0;
  inset-inline-end: 0;
  align-items: center;
  margin: 0;
  border: none;

  legend {
    display: contents;
    color: $yellow;

    @include h6;

    font-weight: bold;
  }

  label {
    @include body-copy-1;

    padding: $g8 $g16;
    color: $fog;

    &:not(:hover) {
      border-color: transparent;
    }

    &:hover {
      border-color: transparent;
      color: $pebble;
      cursor: pointer;
    }
  }

  // visually hide the sort radio buttons;
  // navigate them using styled labels
  input {
    position: absolute;
    margin: 0;
    opacity: 0;
  }

  input:focus-visible + label {
    outline: $focusstatelight;
  }

  span {
    &:first-of-type {
      margin-inline-start: $g16;
    }
  }

  input:checked ~ label {
    @include h5;

    border-radius: $g8;
    // These values are used over border to prevent the labels adjusting position
    // when checked. They don't work if the linter consolidates them into a
    // single rule.
    /* stylelint-disable */
    outline: var(--color);
    outline-style: solid;
    outline-width: 2px;
    /* stylelint-enable */
    color: white;
    font-weight: bold;
  }
}

.search_video_and_web_results__mobile_controls {
  display: flex;
  justify-content: space-between;
  margin-block-end: calc(var(--spacing-block) * 0.5);

  @include breakpoint($sm) {
    display: none;
  }
}

.search_video_and_web_results__mobile_filters_button {
  @include btn;
  @include ghost-btn($white);
}

.search_video_and_web_results__mobile_sort_by {
  @include custom-dropdown($padding-inline-end: $g40);
}

.search_video_and_web_results__filters {
  display: flex;
  position: fixed;
  // This magic number was found based on the top position of the filters,
  // where the sticky started, and the nav based on it scrolling away.
  // This magic number will be fixed when the mobile nav is in place
  inset-block-start: 46px;
  inset-inline-start: 0;
  z-index: 20;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 20px;
  padding-block-end: 60px;
  overflow-y: scroll;
  background: rgba($midnight, 80%);
  backdrop-filter: blur(10px);
}

.search_video_and_web_results__filters_close_icon {
  width: 20px;
  pointer-events: auto;

  &:hover {
    cursor: pointer;
  }
}
