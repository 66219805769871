@use '../includes' as *;

@mixin center-badge {
  position: absolute;
  inset-block-end: 5%;
  inset-inline-start: 50%;
  transform: translateX(-50%);
}

.continue_watching_show_poster {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  overflow: hidden;
  transition-property: scale, opacity, border-radius, outline-color;
  transition-duration: var(--duration);
  transition-timing-function: ease-in-out;
  outline: 3px solid transparent;
  background-color: $pbs-blue;

  &:hover,
  &:focus {
    z-index: 99 !important;
    border-radius: 3px;
    outline-color: $white;
    scale: 1.1;
  }

  img {
    width: 100%;
  }
}

.continue_watching_show_poster__fallback {
  padding: $g8;
  text-align: center;

  @include h5;

  span {
    width: 100%;
  }
}

.continue_watching_show_poster__wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.badges {
  container-type: inline-size;
  position: absolute;
  inset-block-end: 5%;
  width: 100%;
}

.continue_watching_show_poster__badge_long_cw {
  @include center-badge;

  display: none;

  @container (min-width: 190px) {
    display: block;
  }
}

.continue_watching_show_poster__badge_short_cw {
  @include center-badge;

  display: block;

  @container (min-width: 190px) {
    display: none;
  }
}

.continue_watching_show_poster__badge_my_list {
  @include center-badge;
}

.continue_watching_show_poster__progress_bar {
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 2%;

  // The progress bar defaults to rounded ends
  // Removing the border radius for square ends
  &::-webkit-progress-bar {
    border-radius: 0;
  }

  &::-webkit-progress-value {
    border-radius: 0;
  }
}

.continue_watching_menubar__text {
  @include h6;
  @include typography-truncate(1);

  font-size: $f12;

  @include breakpoint($sm) {
    @include h6;
  }
}

.continue_watching_menubar__play_icon {
  $play-icon-responsive-size: clamp(1rem, 0.179vw + 0.943rem, 1.2rem);

  width: $play-icon-responsive-size;
  height: $play-icon-responsive-size;
  margin-inline-end: 3px;
  fill: $white;
}

.continue_watching_menubar__kabob_icon {
  width: 9px;
  margin-inline-end: 3px;

  svg {
    max-height: unset;
  }
}

.continue_watching_menubar__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2% 5% 2% 4%;
  background-color: $shadow;
}

.continue_watching_menubar__icons {
  display: flex;
  align-items: center;

  button,
  button:focus {
    justify-content: end;
    height: 30px;
    padding: unset;
    border: unset;
    outline: unset;
    cursor: pointer;
  }
}

.continue_watching_menu__wrapper {
  $svg-responsive-size: clamp(1rem, 0.357vw + 0.886rem, 1.4rem);

  display: flex;
  container-type: inline-size;
  position: absolute;
  inset-inline: 0;
  inset-block-end: 36px;
  flex-direction: column;
  gap: clamp(0.4rem, 1.429vw - 0.057rem, 2rem);
  width: 100%;
  height: auto;
  padding-block: clamp(0.4rem, 1.429vw - 0.057rem, 2rem);
  padding-inline-start: clamp(0.5rem, 0.893vw + 0.214rem, 1.5rem);
  border-radius: 6px 6px 0 0;
  background: rgba($blue-gray, 80%);
  backdrop-filter: blur(12.5px);

  @include breakpoint($sm) {
    border-radius: 10px 10px 0 0;
  }

  li {
    @include body-copy-3;

    @include breakpoint($sm) {
      @include body-copy-1;
    }

    a {
      display: flex;
      align-items: center;
    }

    svg {
      width: $svg-responsive-size;
      height: $svg-responsive-size;
      margin-inline-end: 10px;
      fill: $white;
    }
  }
}

.continue_watching_menubar__menu_text_long {
  display: none;

  @include breakpoint($md) {
    display: inline;
  }
}

.continue_watching_menubar__menu_text {
  display: inline;

  @include breakpoint($md) {
    display: none;
  }
}

.continue_watching_menu__text_long {
  display: none;

  @container (min-width: 175px) {
    display: inline;
  }
}

.continue_watching_menu__text {
  display: inline;

  @container (min-width: 175px) {
    display: none;
  }
}

.continue_watching_menu__link {
  &:hover {
    svg,
    path {
      fill: var(--link-active-color);
    }
  }
}
