@use '../includes' as *;

.utility_nav {
  display: flex;
  inset: 0 0 auto;
  z-index: 12;
  height: var(--navigation-with-border-height);
  padding-inline-start: var(--spacing-inline);
  // this padding-inline-end is necessary so that
  // the hamburger icon at mobile breakpoints OR
  // the search icon at desktop breakpoints
  // line up with page content
  padding-inline-end: calc(
    var(--spacing-inline) - var(--navigation-spacing-inline)
  );
  transition-property: translate, background-color;
  transition-duration: var(--duration-long);
  border-block-start: $nav-border-width solid $pbs-blue;
  background-color: $midnight;
  font-size: $f16;

  // This is necessary for the hover menus to work
  @include breakpoint($sm) {
    position: fixed;
  }
}

.utility_nav__scrolled {
  background-color: $pbs-blue;
  translate: 0 -5px;
}

.home_link {
  display: flex;
  position: relative;
  align-items: center;
  height: var(--navigation-height);
  padding-inline-end: var(--navigation-spacing-inline);
  overflow: hidden;

  > * {
    display: block;
    height: var(--navigation-image-height);
  }

  &.svp_home_link {
    margin-inline-end: auto;
  }

  .svp_station_logo {
    width: auto;
    height: var(--navigation-image-height);
  }

  &:not(.svp_home_link)::after {
    content: '';
    position: absolute;
    inset-inline-end: 0;
    width: 1px;
    height: var(--navigation-image-height);
    background-color: $pebble;
  }

  @include breakpoint($sm) {
    &::after {
      display: none;
    }
  }
}

.utility_nav_links {
  display: none;
  flex-grow: 1;
  gap: $g24;
  height: 100%;
  padding-inline: var(--navigation-spacing-inline);

  // magic number breakpoint where the links fit
  @include breakpoint($utility-nav-bp) {
    display: flex;
  }

  @include breakpoint($xl) {
    gap: $g32;
  }

  a,
  > * {
    display: flex;
    align-items: center;
    gap: $g4;
    height: 100%;
    color: $white;
  }

  a {
    transition-property: color;
    transition-duration: var(--duration);
    transition-timing-function: ease-in;

    &:hover,
    &:focus-visible {
      color: $pebble;
      text-decoration: none;

      path,
      circle {
        fill: currentcolor;
      }
    }
  }

  .utility_nav_link_active {
    a {
      font-weight: $bold;
    }
  }
}

.utility_nav_links li.push {
  margin-inline-start: auto;
}

.live_tv_icon {
  width: 11px;
  height: 11px;
}

.shows_icon {
  width: 17px;
  height: 16px;
}

.my_station_icon {
  width: 13px;
  height: 20px;
}
