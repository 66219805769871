@use '../../components/includes' as *;

.search_landing__loading {
  margin-block-end: var(--spacing-inline);
}

.search_landing__content {
  margin-inline: var(--spacing-inline);

  @include breakpoint($sm) {
    display: grid;
    grid-template-areas:
      'filters  results'
      '.        pagination';
    grid-template-columns: 20% 1fr;
    gap: 0 var(--spacing-inline);
  }
}

.search_landing__results {
  grid-area: results;
}

.search_landing__filters {
  display: none;
  grid-area: filters;

  @include breakpoint($sm) {
    display: block;
  }
}
