@use '../includes' as *;

@mixin badge() {
  --range: 2.2vw + 0.1em;
  // note the block values are smaller than what figma shows
  // these values *look* right
  padding-block: clamp(1px, var(--range), 3px);
  padding-inline: clamp(6px, var(--range), 12px);
  border-radius: 5px;
  background-color: var(--background-color, #{$yellow});
  color: var(--color, #{$midnight});
  font-size: clamp($f10, var(--range), $f14);
  font-weight: $bold;
  text-transform: uppercase;
  white-space: nowrap;
}

@mixin outline-badge() {
  @include badge;

  border: 2px solid var(--color, #{$yellow});
  background-color: transparent;
  color: var(--color, #{$yellow});
}

.yellow {
  @include badge;

  --background-color: #{$yellow};
  --color: #{$midnight};
}

.teal {
  @include badge;

  --background-color: #{$teal};
  --color: #{$midnight};
}

.passport {
  @include badge;

  --background-color: #{$passport-blue};
  --color: #{$white};
}

.red {
  @include badge;

  --background-color: #{$red};
  --color: #{$white};
}

.coral {
  @include badge;

  --background-color: #{$coral};
  --color: #{$midnight};
}

.coral_midnight {
  @include badge;

  --background-color: #{$midnight};
  --color: #{$coral};
}

.teal_midnight {
  @include badge;

  --background-color: #{$midnight};
  --color: #{$teal};
}

.live {
  @include outline-badge;

  --color: #{$white};

  display: flex;
  position: relative;
  align-items: center;
  gap: $g4;
  padding-block: 2px;
  padding-inline-start: $g4;
  padding-inline-end: 6px;
  border-radius: 0;
  box-shadow: 0 0 12px 0 rgba($midnight, 40%);
  text-shadow: 0 0 12px rgba($midnight, 85%);

  &::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $red;
    box-shadow: 0 0 12px 0 rgba($midnight, 40%);
  }
}

.genre,
a.genre {
  --color: #{$pebble};

  @include outline-badge;

  padding-inline: clamp(6px, var(--range), 8px);
  transition-property: border-color, color;
  transition-duration: var(--duration-half);
  transition-timing-function: ease-in;
  border-width: 1.5px;
  font-size: $f14;
  font-weight: $normal;
  text-transform: capitalize;

  &:hover,
  &:focus {
    --color: #{$fog};

    text-decoration: none;
  }
}

.rating,
a.rating {
  @include outline-badge;

  --color: #{$pebble};

  padding-inline: clamp(6px, var(--range), 8px);
  border-width: 1.5px;
  font-size: $f14;
  font-weight: $normal;
  text-transform: capitalize;
}

.yellow_outline {
  @include outline-badge;

  --color: #{$yellow};
}

.teal_outline {
  @include outline-badge;

  --color: #{$teal};
}

.passport_outline {
  @include outline-badge;

  --color: #{$passport-blue};
}

.red_outline {
  @include outline-badge;

  --color: #{$red};
}
