@use '../includes' as *;

.season_navigator {
  p {
    @include body-copy-1;
  }
}

.season_navigator_select {
  @include custom-dropdown($padding-inline-end: $g40);

  margin-block-end: $g32;

  option {
    background-color: $white;
    color: $midnight;
  }
}

select.season_navigator_select {
  padding-inline-end: $g40;
}

.season_navigator__episodes {
  @include video-detail-thumbnail-list;
}

.season_navigator__loading {
  max-width: 400px;
}
