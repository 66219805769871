@use '../../includes' as *;

.my_list_viewing_history {
  container-type: inline-size;
}

.my_list_viewing_history_user_prompt {
  height: 100%;
  padding: 10cqw $g32;
  text-align: center;

  @include breakpoint($md) {
    padding-block: 20cqh;
  }

  h2 {
    @include h5;

    margin-block-end: $g20;
  }
}
