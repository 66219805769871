@use '../includes' as *;

.video_thumbnail {
  display: flex;
  flex-direction: column;
  gap: $g4;
}

.parent_link_wrapper {
  // we need this extra 4px of margin for the title to look optically spaced evenly
  margin-block-start: $g4;
}

.parent_link {
  @include over-title;
}

.video_link {
  @include h5;
  @include typography-truncate(2);
}

.video_meta {
  @include body-copy-3($pebble);
  @include typography-truncate(1);

  // Needs to be after the includes for the uppercasing to work
  display: inline-block;

  &::first-letter {
    text-transform: uppercase;
  }
}
