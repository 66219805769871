@use '../includes' as *;

.newsletter {
  position: relative;
  width: 100%;
  max-height: 250px;
  margin-block-end: var(--spacing-block);
  overflow: hidden;
  background-color: $midnight;
  color: $white;
}

.background {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 75% 0;

    .civics & {
      object-position: 0 0;
    }
  }

  &::before {
    content: '';
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      270deg,
      transparent,
      rgba($midnight, 0.8) 70%
    );
    mix-blend-mode: normal;
  }

  // Special gradient for Science newsletter
  .science & {
    &::before {
      background-image: linear-gradient(
        90deg,
        rgba($midnight, 0.8) 0%,
        transparent 100%
      );
    }
  }
}

.inner {
  position: relative;
  max-width: 150ch;
  min-height: 170px;
  max-height: 240px;
  padding-block: $g20;
  padding-inline: var(--spacing-inline);

  @include breakpoint($xs) {
    padding-block: calc(var(--spacing-block) * 0.75);
  }
}

.inner_header {
  @include h2($white);

  margin-block-end: $g16;
  font-size: clamp($f14, 1.1333rem + 0.8333vw, $f22);
}

.inner_text {
  @include body-copy-3($white);

  margin-block-end: $g16;

  @include breakpoint($md) {
    @include body-copy-1($white);
  }
}

.inner_form {
  display: grid;
  position: inherit;
  grid-template-areas:
    'input   button  .'
    'message message message';
  grid-template-columns: 1fr;
  margin-block-start: $g16;
  color: #000;
  caret-color: $coral;

  @include breakpoint($sm) {
    grid-template-columns: minmax(0, 49rem) 14.3rem 1fr;
  }

  @include breakpoint($md) {
    margin-block-start: $g8;
  }
}

.form__input {
  grid-area: input;
  height: $g36;
  padding: $g8;
  border: $white;
  border-start-start-radius: $radius-med;
  border-end-start-radius: $radius-med;
  background-color: rgb(255 255 255 / 20%);
  color: $fog;
  font-size: $f14;

  &::placeholder {
    padding-inline-start: $g12;
    color: $cloud;
  }
}

.form__submit_button {
  @include btn;
  @include fill-btn;

  grid-area: button;
  max-width: fit-content;
  border-start-start-radius: 0 !important;
  border-start-end-radius: $radius-med !important;
  border-end-start-radius: 0 !important;
  border-end-end-radius: $radius-med !important;
}

.form__message {
  grid-area: message;
  margin: $g16 0 0 0;
  color: $white;
  font-size: $f18;
  font-weight: $bold;
  line-height: $line-height;

  @include breakpoint($sm) {
    font-size: $f24;
  }
}

.loading_indicator {
  div {
    div {
      width: 14px;
      height: 14px;
    }
  }
}
