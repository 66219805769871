@use '../../includes' as *;

.my_list_video_grid_item {
  position: relative;
}

.my_list_video_kabob_menu {
  @include my-list-kabob-menu;

  @media (hover: hover) {
    opacity: 0;

    &:focus-visible,
    .my_list_video_grid_item:hover &,
    .my_list_video_grid_item:has(> a:focus) & {
      opacity: 1;
      scale: 1.1;
    }
  }
}

.my_list_video_link,
.my_list_video_show_link,
.my_list_video_remove_button {
  display: flex;
  align-items: center;
  gap: $g12;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: $fog;
  cursor: pointer;

  // stylint thinks this is descneding specificty, but it's not
  // stylelint-disable-next-line
  svg {
    width: 15px;
    height: 15px;
  }

  &:hover,
  &:focus {
    color: $pebble;

    svg,
    path,
    rect {
      fill: currentcolor;
    }
  }
}
