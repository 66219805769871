@use 'sass:color';
@use '../includes' as *;

$bp: $xxs;

// prevent scrolling in the background when the sign in modal is open
body:has(.signin_modal[open]) {
  overflow: hidden;
}

.signin_modal {
  width: 100vw;
  background-color: rgba($navy-blue, 0.5);
  backdrop-filter: blur(15px);
  color: var(--text-color);

  // safari has a bad time with `revert`, so we
  // need to use a max-width breakpoint here
  @include breakpoint(max-width $bp - 1) {
    inset: 0;
    height: 100vh;
    margin: 0;
  }

  @include breakpoint($bp) {
    width: clamp(368px, 35vw, 380px);
    border-radius: 6px;
    background-color: rgba($navy-blue, 0.8);
    backdrop-filter: blur(10px);

    &::backdrop {
      background-color: rgba($midnight, 0.5);
      backdrop-filter: blur(15px);
    }
  }

  .signin_modal_inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: $g16;

    @include breakpoint($bp) {
      height: fit-content;
      padding: $g40;
    }
  }

  .pbs_head_logo {
    max-width: 42px;
    margin-block-end: $g16;
  }

  .my_list_blurb {
    @include body-copy-3;

    // this max width breaks the blurb where the designs indicate
    max-width: 270px;
    margin-block-end: $g16;
    font-size: $f12;
    text-align: center;
  }

  .email_btn {
    @include btn;
    @include fill-btn($white);

    justify-content: center;
    width: 100%;
    margin-block-end: $g8;
    font-size: clamp($f12, 1.2vw + 1em, $f14);
  }

  .create_account {
    @include body-copy-3;

    margin-block-end: $g16;
    font-size: $f12;

    > a {
      color: $yellow;
      font-weight: $bold;
    }
  }

  .or {
    position: relative;
    z-index: 1;
    width: 100%;
    margin-block-end: $g20;
    color: $fog;
    font-size: $f12;
    font-weight: $bold;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;

    // these pseudo elements make the bifurcated line
    &::before,
    &::after {
      content: ' ';
      display: block;
      position: absolute;
      z-index: 0;
      width: calc(50% - 20px);
      height: 1px;
      background-color: $blue-gray;
    }

    &::before {
      inset: 6px auto 0 0;
    }

    &::after {
      inset: 6px 0 0 auto;
    }
  }

  .social_btn {
    @include btn($isolate-svg-color: true);
    @include ghost-btn($white, $isolate-svg-color: true);

    justify-content: center;
    width: 100%;
    height: 36px;
    margin-block-end: $g12;
    font-size: clamp($f12, 1.2vw + 1em, $f14);

    svg {
      transition:
        // not sure why but linear is needed here for it to look right
        fill var(--duration) linear,
        stroke var(--duration) linear,
        opacity var(--duration) linear;
    }

    .facebook_logo {
      width: 20px;
      height: 20px;
      fill: $facebook-blue;
    }

    .apple_logo {
      width: 24px;
      max-width: none;
      height: 24px;
      fill: $white;
      translate: 0 -1px;

      path {
        max-width: none;
        height: auto;
      }
    }

    &:hover,
    &:focus {
      .google_logo {
        opacity: 0.7;
      }

      .facebook_logo {
        opacity: 0.8;
        fill: color.mix($onyx, $facebook-blue, 30);
      }

      .apple_logo {
        opacity: 0.8;
        fill: color.mix($onyx, $white, 30);
      }
    }

    &:last-of-type {
      margin-block-end: $g20;
    }
  }

  .disclaimer {
    @include body-copy-3($pebble);

    font-size: $f12;

    a {
      color: currentcolor;
      text-decoration: underline;
    }
  }
}
