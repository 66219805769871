@use '../includes' as *;

// Approximating the splide arrows to the center of the
// thumbnail image vs. the splide slide
.featured_news_row {
  @include breakpoint($below-sm) {
    [class*='splide__arrow--prev'],
    [class*='splide__arrow--next'] {
      inset-block-start: 40%;
    }
  }
}

.featured_news_row_title {
  @include h3;

  margin-block-end: clamp(0.4rem, 1.071vw + 0.057rem, 1.6rem);
}

// Need to bump the specificity to apply
// the style else the original splide styles
// will override it in Amplify & Edcar environments
button.featured_news_row_splide_arrow {
  @include splide-arrow;

  @include breakpoint(1500px) {
    display: none;
  }
}
